import styled from 'styled-components';
import BannerBG from 'common/assets/image/saasModern/banner-hero.webp';
import colors from 'common/theme/saasModern/colors';
import { saasModernTheme } from 'common/theme/saasModern';

export const BannerComponent = styled.section`
  width: 100%;
  height: 110vh;
  background-image: url(${BannerBG?.src});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  @media (max-width: 950px) {
    height: 100vh;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${saasModernTheme.space[8]}px;
  background: linear-gradient(
    180deg,
    #1b1326 0%,
    rgba(41, 37, 59, 0.55) 81.5%,
    rgba(52, 50, 74, 0) 100%
  );
`;

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${saasModernTheme.space[9]}px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const HeadLine = styled.h1`
  width: 70%;
  color: ${colors.white};
  font-size: ${saasModernTheme.space[16]}px;
  font-weight: 900;
  text-align: center;
  margin: 0;
  @media (max-width: 1024px) {
    font-size: ${saasModernTheme.space[14]}px;
    width: 85%;
  }
  @media (max-width: 950px) {
    
    font-size: ${saasModernTheme.space[11]}px;
  }
  @media (max-width: 600px) {
    width: 90%;
    font-size: ${saasModernTheme.space[9]}px;
  }
  @media (max-width: 400px) {
    width: 90%;
    font-size: ${saasModernTheme.space[7]}px;
  }
`;

export const Description = styled.p`
  
  font-size: ${saasModernTheme.space[6]}px;
  font-weight: 600;
  text-align: center;
  width: 40%;
  color: ${colors.white};
  margin: 0;
  @media (max-width: 1024px) {
    font-size: ${saasModernTheme.space[5]}px;
    width: 70%;
  }
  @media (max-width: 950px) {
    font-size: ${saasModernTheme.space[5]}px;
    width: 60%;
  }
  @media (max-width: 600px) {
    width: 90%;
    font-size: ${saasModernTheme.space[4]}px;
  }
`;

export const BannerWrapperNav = styled.div``;
